import React from 'react';
import { Link } from 'react-router-dom';
import { TriathlonAnimation } from '../animations/TriathlonAnimation';
import '../../styles/animations.css';

export function AuthLanding() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-surface to-background px-4 sm:px-6 lg:px-8">
      {/* Main content wrapper with relative positioning */}
      <div className="flex-1 flex flex-col items-center justify-center">
        {/* Animation positioned to overlap */}
        <div className="w-full max-w-md mx-auto mb-[-80px]"> {/* Negative margin to create overlap */}
          <TriathlonAnimation />
        </div>

        {/* Title section */}
        <div className="text-center space-y-4 relative"> {/* Added relative for z-index context */}
          <h2 className="text-5xl font-bold text-secondary relative z-10"> {/* z-10 to ensure text is above animation */}
            Pangzi Fitness
          </h2>
          <p className="text-xl text-secondary/60 relative z-10">
            Track your triathlon journey, set goals, and achieve more.
          </p>
        </div>
      </div>

      {/* Bottom section with buttons */}
      <div className="w-full max-w-md mx-auto pb-12 px-4 space-y-4">
        <Link
          to="/auth/signup"
          className="w-full inline-flex justify-center items-center px-4 py-4 border border-transparent text-lg font-medium rounded-xl text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors shadow-lg hover:shadow-xl"
        >
          Create Account
        </Link>
        <Link
          to="/auth/signin"
          className="w-full inline-flex justify-center items-center px-4 py-4 border-2 border-primary text-lg font-medium rounded-xl text-primary hover:bg-primary/10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors shadow-lg hover:shadow-xl"
        >
          Sign In
        </Link>
      </div>
    </div>
  );
}
