import { useWorkouts } from '../context/WorkoutContext';
import { workoutTypes } from '../types/workout';

const Progress = () => {
  const { workouts, getWorkoutStats } = useWorkouts();
  const stats = getWorkoutStats();

  // Calculate weekly stats
  const getWeeklyStats = () => {
    const now = new Date();
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    const weeklyWorkouts = workouts.filter(workout => workout.date >= startOfWeek);

    return {
      totalWorkouts: weeklyWorkouts.length,
      totalDuration: weeklyWorkouts.reduce((acc, workout) => 
        acc + ((workout.duration.hours || 0) * 60 + (workout.duration.minutes || 0)), 0),
      averageIntensity: weeklyWorkouts.length > 0
        ? weeklyWorkouts.reduce((acc, workout) => acc + workout.intensity, 0) / weeklyWorkouts.length
        : 0
    };
  };

  const weeklyStats = getWeeklyStats();

  const formatDuration = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours === 0) return `${minutes} min`;
    if (minutes === 0) return `${hours} hr`;
    return `${hours} hr ${minutes} min`;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-secondary mb-8">Progress Overview</h1>

      {/* Weekly Stats */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold text-secondary mb-4">This Week</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Workouts</h3>
            <p className="text-2xl font-bold text-secondary">{weeklyStats.totalWorkouts}</p>
          </div>
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Total Time</h3>
            <p className="text-2xl font-bold text-secondary">{formatDuration(weeklyStats.totalDuration)}</p>
          </div>
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Avg Intensity</h3>
            <p className="text-2xl font-bold text-secondary">{weeklyStats.averageIntensity.toFixed(1)} / 3</p>
          </div>
        </div>
      </div>

      {/* Overall Stats */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold text-secondary mb-4">Overall Progress</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Total Workouts</h3>
            <p className="text-2xl font-bold text-secondary">{stats.totalWorkouts}</p>
          </div>
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Total Time</h3>
            <p className="text-2xl font-bold text-secondary">{formatDuration(stats.totalDuration)}</p>
          </div>
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Avg Intensity</h3>
            <p className="text-2xl font-bold text-secondary">{stats.averageIntensity.toFixed(1)} / 3</p>
          </div>
          <div className="card">
            <h3 className="text-sm font-medium text-secondary/70 mb-1">Favorite Activity</h3>
            <p className="text-2xl font-bold text-secondary">
              {Object.entries(stats.workoutsByType)
                .sort(([,a], [,b]) => b - a)[0]?.[0]
                ? workoutTypes.find(t => t.id === Object.entries(stats.workoutsByType)
                    .sort(([,a], [,b]) => b - a)[0]?.[0])?.name
                : 'N/A'}
            </p>
          </div>
        </div>
      </div>

      {/* Workout Distribution */}
      <div>
        <h2 className="text-xl font-semibold text-secondary mb-4">Workout Distribution</h2>
        <div className="card">
          <div className="space-y-4">
            {Object.entries(stats.workoutsByType).sort(([,a], [,b]) => b - a).map(([type, count]) => {
              const workoutType = workoutTypes.find(t => t.id === type);
              const percentage = (count / stats.totalWorkouts * 100).toFixed(1);
              
              return (
                <div key={type} className="flex items-center space-x-4">
                  <div className="flex-1">
                    <div className="flex justify-between mb-1">
                      <span className="text-sm font-medium text-secondary">
                        {workoutType?.icon} {workoutType?.name}
                      </span>
                      <span className="text-sm text-secondary/70">{percentage}%</span>
                    </div>
                    <div className="w-full bg-surface-light rounded-full h-2">
                      <div
                        className="bg-primary rounded-full h-2"
                        style={{ width: `${percentage}%` }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
