import { XMarkIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useState } from 'react';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar = ({ isOpen, onClose }: SidebarProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      // Error is already handled in AuthContext
      console.debug('Sign out completed with cleanup');
    }
  };

  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/80 z-40"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 bottom-0 w-64 bg-surface border-r border-surface-light z-50 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="h-full flex flex-col">
          <div className="flex-1 p-4">
            <div className="flex items-center justify-between mb-8">
              <div className="flex items-center space-x-2">
                <span className="text-base font-bold bg-primary text-white px-1.5 py-0.5 rounded">PF</span>
                <div className="flex flex-col">
                  <span className="text-lg font-semibold">Pangzi Fitness</span>
                  <span className="text-[10px] text-gray-500 italic">The fatter you are, the more you need help</span>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-surface-light rounded-lg text-secondary"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            <div className="flex flex-col space-y-1">
              <Link
                to="/"
                className="p-3 hover:bg-surface-light rounded-lg text-secondary hover:text-primary transition-colors"
                onClick={onClose}
              >
                Dashboard
              </Link>
              <Link
                to="/schedule"
                className="p-3 hover:bg-surface-light rounded-lg text-secondary hover:text-primary transition-colors"
                onClick={onClose}
              >
                Schedule
              </Link>
              <Link
                to="/workouts"
                className="p-3 hover:bg-surface-light rounded-lg text-secondary hover:text-primary transition-colors"
                onClick={onClose}
              >
                Workouts
              </Link>
              <Link
                to="/progress"
                className="p-3 hover:bg-surface-light rounded-lg text-secondary hover:text-primary transition-colors"
                onClick={onClose}
              >
                Progress
              </Link>
            </div>
          </div>
          
          {/* Sign out button at bottom */}
          <div className="p-4 border-t border-surface-light">
            <button
              onClick={handleSignOut}
              className="w-full flex items-center justify-center space-x-2 p-3 bg-primary hover:bg-primary/90 text-white rounded-lg transition-colors"
            >
              <ArrowRightOnRectangleIcon className="h-5 w-5" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
