import { Bars3Icon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useState } from 'react';

interface NavbarProps {
  onMenuClick: () => void;
}

const Navbar = ({ onMenuClick }: NavbarProps) => {
  const { user, signOut } = useAuth();
  const [isSigningOut, setIsSigningOut] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    if (isSigningOut) return;
    
    try {
      setIsSigningOut(true);
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      setIsSigningOut(false);
      navigate('/auth/signin');
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 h-16 bg-surface border-b border-surface-light z-50">
      <div className="h-full px-4 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button onClick={onMenuClick} className="p-2 hover:bg-surface-light rounded-lg">
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-2">
            <span className="text-base font-bold bg-primary text-white px-1.5 py-0.5 rounded">PF</span>
            <div className="flex flex-col">
              <span className="text-lg font-semibold">Pangzi Fitness</span>
              <span className="text-[10px] text-gray-500 italic">The fatter you are, the more you need help</span>
            </div>
          </div>
        </div>
        
        <div className="flex items-center">
          {/* Navigation links - only visible on desktop */}
          <div className="hidden md:flex items-center space-x-6 mr-4">
            <Link to="/" className="nav-link">Dashboard</Link>
            <Link to="/schedule" className="nav-link">Schedule</Link>
            <Link to="/workouts" className="nav-link">Workouts</Link>
            <Link to="/progress" className="nav-link">Progress</Link>
          </div>
          
          {/* Sign out button - visible only on desktop */}
          <button
            onClick={handleSignOut}
            disabled={isSigningOut}
            className="hidden md:block p-2 text-gray-600 hover:text-primary hover:bg-surface-light rounded-lg transition-colors"
            title="Sign out"
          >
            <ArrowRightOnRectangleIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
