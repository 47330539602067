export const FaceHappy = () => (
  <svg viewBox="0 0 24 24" fill="none" className="w-8 h-8">
    <path
      d="M16 14c.3-.2.7-.4 1-.4a3 3 0 0 1 3 3 2 2 0 0 1-2 2H6a2 2 0 0 1-2-2 3 3 0 0 1 3-3c.3 0 .7.2 1 .4a5 5 0 0 0 8 0M15 9h.01M9 9h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FaceNeutral = () => (
  <svg viewBox="0 0 24 24" fill="none" className="w-8 h-8">
    <path
      d="M9 9h.01M15 9h.01M8 14h8m6-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FaceSad = () => (
  <svg viewBox="0 0 24 24" fill="none" className="w-8 h-8">
    <path
      d="M16 16c-.3.2-.7.4-1 .4a5 5 0 0 1-6 0c-.3 0-.7-.2-1-.4a3 3 0 0 0-3 3 2 2 0 0 0 2 2h10a2 2 0 0 0 2-2 3 3 0 0 0-3-3ZM15 9h.01M9 9h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
