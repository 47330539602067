// Workout type definitions
export interface Duration {
  hours: number | null;
  minutes: number | null;
}

export interface Distance {
  value: number;
  unit: 'km' | 'miles';
}

// Workout type definition
export interface WorkoutType {
  id: string;
  name: string;
  icon: string;
  hasDistance: boolean;
}

// Define workout type IDs as constants
export const WORKOUT_TYPE = {
  RUNNING: 'running',
  CYCLING: 'cycling',
  SWIMMING: 'swimming'
} as const;

// Available workout types
export const workoutTypes: readonly WorkoutType[] = [
  { id: WORKOUT_TYPE.RUNNING, name: 'Running', icon: '🏃‍♂️', hasDistance: true },
  { id: WORKOUT_TYPE.CYCLING, name: 'Cycling', icon: '🚴‍♂️', hasDistance: true },
  { id: WORKOUT_TYPE.SWIMMING, name: 'Swimming', icon: '🏊‍♂️', hasDistance: true }
] as const;

// Workout interface
export interface Workout {
  id: string;
  type: string;
  date: Date;
  duration: Duration;
  distance?: Distance;
  intensity: number;
  trainingZone: number;
  notes?: string;
  user_id?: string;
}

// Form data type
export type WorkoutFormData = Omit<Workout, 'id'>;
