import { Workout } from '../types/workout';
import { supabase } from '../lib/supabase';

const STORAGE_KEY = 'fitness_tracker_workouts';

export const saveWorkout = async (workout: Omit<Workout, 'id'>): Promise<Workout> => {
  try {
    // Create a clean object with exactly the fields we want to send
    const workoutData = {
      user_id: workout.user_id,
      type: workout.type,
      date: workout.date.toISOString(),
      hours: workout.duration.hours || 0,
      minutes: workout.duration.minutes || 0,
      training_zone: workout.trainingZone,
      intensity: workout.intensity,
      distance_value: workout.distance?.value || null,
      distance_unit: workout.distance?.unit || null,
      notes: workout.notes || null
    };

    const { data, error } = await supabase
      .from('workouts')
      .insert([workoutData])
      .select()
      .single();

    if (error) {
      console.error('Error saving workout:', error);
      throw new Error(error.message || 'Failed to save workout');
    }

    if (!data) {
      throw new Error('No data returned from insert operation');
    }

    // Convert the database record back to our app's Workout type
    return {
      id: data.id,
      user_id: data.user_id,
      type: data.type,
      date: new Date(data.date),
      duration: {
        hours: data.hours || 0,
        minutes: data.minutes || 0
      },
      trainingZone: data.training_zone,
      intensity: data.intensity,
      ...(data.distance_value && {
        distance: {
          value: data.distance_value,
          unit: data.distance_unit as 'km' | 'miles'
        }
      }),
      ...(data.notes && { notes: data.notes })
    };
  } catch (err) {
    console.error('Error in saveWorkout:', err);
    throw err;
  }
};

export const getWorkouts = async (): Promise<Workout[]> => {
  const { data, error } = await supabase
    .from('workouts')
    .select('*')
    .order('date', { ascending: false });

  if (error) {
    console.error('Error fetching workouts:', error);
    throw error;
  }

  if (!data) {
    return [];
  }

  // Convert database records to Workout type
  return data.map(record => ({
    id: record.id,
    user_id: record.user_id,
    type: record.type,
    date: new Date(record.date),
    duration: {
      hours: record.hours || 0,
      minutes: record.minutes || 0
    },
    trainingZone: record.training_zone,
    intensity: record.intensity,
    ...(record.distance_value && {
      distance: {
        value: record.distance_value,
        unit: record.distance_unit as 'km' | 'miles'
      }
    }),
    ...(record.notes && { notes: record.notes })
  }));
};

export const updateWorkout = async (workout: Workout): Promise<void> => {
  // Ensure the date is properly formatted for PostgreSQL
  const workoutData = {
    ...workout,
    date: workout.date.toISOString(),
    // Flatten duration object for database
    hours: workout.duration.hours || 0,
    minutes: workout.duration.minutes || 0,
    training_zone: workout.trainingZone,
    // Remove the nested duration object and renamed fields
    duration: undefined,
    trainingZone: undefined,
    // Ensure distance is properly formatted
    distance_value: workout.distance?.value,
    distance_unit: workout.distance?.unit,
    distance: undefined
  };

  const { error } = await supabase
    .from('workouts')
    .update(workoutData)
    .eq('id', workout.id);

  if (error) {
    console.error('Error updating workout:', error);
    throw error;
  }
};

export const deleteWorkout = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('workouts')
    .delete()
    .eq('id', id);

  if (error) {
    console.error('Error deleting workout:', error);
    throw error;
  }
};
