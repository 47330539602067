import { useState } from 'react';
import { useWorkouts } from '../context/WorkoutContext';
import { workoutTypes } from '../types/workout';
import WorkoutForm from '../components/WorkoutForm';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Schedule = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { workouts, addWorkout } = useWorkouts();

  // Get the current month's calendar data
  const getDaysInMonth = (year: number, month: number) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay(); // 0 = Sunday

    const calendarDays: Array<{ date: Date | null; hasWorkout: boolean }> = [];

    // Add empty days for the start of the month
    for (let i = 0; i < startingDay; i++) {
      calendarDays.push({ date: null, hasWorkout: false });
    }

    // Add all days in the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      const hasWorkout = workouts.some(workout => {
        const workoutDate = new Date(workout.date);
        return (
          workoutDate.getFullYear() === currentDate.getFullYear() &&
          workoutDate.getMonth() === currentDate.getMonth() &&
          workoutDate.getDate() === currentDate.getDate()
        );
      });
      calendarDays.push({ date: currentDate, hasWorkout });
    }

    // Add empty days to complete the last week if needed
    while (calendarDays.length % 7 !== 0) {
      calendarDays.push({ date: null, hasWorkout: false });
    }

    return calendarDays;
  };

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const calendarDays = getDaysInMonth(currentYear, currentMonth);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getWorkoutsForDate = (date: Date) => {
    return workouts.filter(workout => {
      const workoutDate = new Date(workout.date);
      return (
        workoutDate.getFullYear() === date.getFullYear() &&
        workoutDate.getMonth() === date.getMonth() &&
        workoutDate.getDate() === date.getDate()
      );
    });
  };

  const formatDate = (date: Date | string) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    return dateObj.toLocaleDateString();
  };

  const formatDuration = (duration: { hours: number; minutes: number }) => {
    if (duration.hours === 0) return `${duration.minutes} min`;
    if (duration.minutes === 0) return `${duration.hours} hr`;
    return `${duration.hours}h ${duration.minutes}m`;
  };

  const navigateMonth = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    } else {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    }
  };

  const selectedWorkouts = selectedDate ? getWorkoutsForDate(selectedDate) : [];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-secondary">Training Schedule</h1>
        <button
          onClick={() => setIsFormOpen(true)}
          className="btn btn-primary"
        >
          Schedule Workout
        </button>
      </div>
      
      <div className="card mb-8 max-w-5xl mx-auto">
        {/* Calendar Header */}
        <div className="flex justify-between items-center mb-6 sm:mb-8 px-4 sm:px-8">
          <button
            onClick={() => navigateMonth('prev')}
            className="text-secondary hover:text-primary text-2xl p-2"
          >
            ←
          </button>
          <h2 className="text-xl sm:text-2xl font-semibold text-secondary">
            {monthNames[currentMonth]} {currentYear}
          </h2>
          <button
            onClick={() => navigateMonth('next')}
            className="text-secondary hover:text-primary text-2xl p-2"
          >
            →
          </button>
        </div>

        {/* Calendar Grid */}
        <div className="grid grid-cols-7 gap-px mb-px px-4 sm:px-8">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className="text-center font-medium text-secondary py-2 text-sm">
              {day}
            </div>
          ))}
        </div>
        
        <div className="grid grid-cols-7 gap-px px-4 sm:px-8 pb-4 sm:pb-8">
          {calendarDays.map((day, index) => {
            if (!day.date) {
              return (
                <div
                  key={index}
                  className="aspect-square sm:aspect-auto flex items-center justify-center text-base text-secondary/30 sm:h-[5.5rem] h-[4.5rem]"
                />
              );
            }

            const isToday = day.date.toDateString() === new Date().toDateString();

            return (
              <button
                key={index}
                onClick={() => setSelectedDate(day.date)}
                className={`
                  relative flex flex-col items-center justify-start
                  text-base
                  transition-colors
                  sm:h-[5.5rem] h-[4.5rem]
                  hover:bg-surface-light/50
                  ${isToday ? 'font-bold' : 'font-normal'}
                  ${day.hasWorkout
                    ? 'bg-primary/10 hover:bg-primary/20'
                    : ''
                  }
                `}
              >
                <span className={`
                  flex items-center justify-center
                  w-7 h-7 mt-2
                  ${isToday ? 'bg-primary text-background rounded-full' : ''}
                `}>
                  {day.date.getDate()}
                </span>
                {day.hasWorkout && (
                  <div className="text-[0.65rem] text-primary font-medium mt-1 hidden sm:block">
                    Workout
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>

      {/* Workout Details Modal */}
      {selectedDate && selectedWorkouts.length > 0 && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
          <div className="bg-surface rounded-xl p-6 w-full max-w-md relative">
            <button
              onClick={() => setSelectedDate(null)}
              className="absolute top-4 right-4 text-secondary hover:text-primary"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>

            <h2 className="text-xl font-semibold mb-4 text-secondary">
              Workouts on {selectedDate.toLocaleDateString()}
            </h2>
            
            <div className="space-y-4">
              {selectedWorkouts.map((workout) => {
                const workoutType = workoutTypes.find(t => t.id === workout.type);
                return (
                  <div key={workout.id} className="border-b border-surface-light pb-4 last:border-0">
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="font-medium text-secondary">
                          {workoutTypes.find(t => t.id === workout.type)?.name}
                        </h3>
                        <p className="text-secondary/70">
                          {formatDate(workout.date)}
                        </p>
                      </div>
                      <div className="text-right text-secondary/80">
                        <p>Duration: {formatDuration({
                          hours: workout.duration.hours || 0,
                          minutes: workout.duration.minutes || 0
                        })}</p>
                        <p>Zone: Z{workout.trainingZone}</p>
                        {workout.distance && (
                          <p>Target: {workout.distance.value} {workout.distance.unit}</p>
                        )}
                      </div>
                    </div>
                    {workout.notes && (
                      <p className="mt-4 text-sm text-secondary/70">
                        {workout.notes}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <WorkoutForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={addWorkout}
      />
    </div>
  );
};

export default Schedule;
