import { useWorkouts } from '../context/WorkoutContext';
import { workoutTypes } from '../types/workout';
import { FireIcon, ClockIcon, ChartBarIcon, CalendarIcon } from '@heroicons/react/24/outline';

const Dashboard = () => {
  const { recentWorkouts, getWorkoutStats } = useWorkouts();
  const stats = getWorkoutStats();

  const formatDuration = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours === 0) return `${minutes} min`;
    if (minutes === 0) return `${hours} hr`;
    return `${hours} hr ${minutes} min`;
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }).format(date);
  };

  return (
    <div className="max-w-7xl mx-auto py-6 px-4 relative min-h-screen">
      {/* Decorative background effect */}
      <div 
        className="absolute inset-0 overflow-hidden pointer-events-none"
        style={{
          background: `
            radial-gradient(circle 600px at 20% 20%, rgba(163, 236, 62, 0.02) 0%, transparent 80%),
            radial-gradient(circle 400px at 70% 40%, rgba(163, 236, 62, 0.015) 0%, transparent 80%)
          `
        }}
      />

      {/* Main content */}
      <div className="relative">
        <h1 className="text-2xl font-bold mb-6 text-secondary">Dashboard</h1>
        
        {/* Stats Grid */}
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="card flex items-center p-4" style={{ background: 'rgba(17, 17, 17, 0.8)', backdropFilter: 'blur(4px)' }}>
            <FireIcon className="w-6 h-6 sm:w-8 sm:h-8 text-primary mr-3 sm:mr-4 flex-shrink-0" />
            <div className="min-w-0">
              <p className="text-xs sm:text-sm text-secondary truncate">Total Workouts</p>
              <p className="text-lg sm:text-2xl font-bold text-secondary">{stats.totalWorkouts}</p>
            </div>
          </div>
          <div className="card flex items-center p-4" style={{ background: 'rgba(17, 17, 17, 0.8)', backdropFilter: 'blur(4px)' }}>
            <ClockIcon className="w-6 h-6 sm:w-8 sm:h-8 text-primary mr-3 sm:mr-4 flex-shrink-0" />
            <div className="min-w-0">
              <p className="text-xs sm:text-sm text-secondary truncate">Total Duration</p>
              <p className="text-lg sm:text-2xl font-bold text-secondary">{formatDuration(stats.totalDuration)}</p>
            </div>
          </div>
          <div className="card flex items-center p-4" style={{ background: 'rgba(17, 17, 17, 0.8)', backdropFilter: 'blur(4px)' }}>
            <ChartBarIcon className="w-6 h-6 sm:w-8 sm:h-8 text-primary mr-3 sm:mr-4 flex-shrink-0" />
            <div className="min-w-0">
              <p className="text-xs sm:text-sm text-secondary truncate">Average Intensity</p>
              <p className="text-lg sm:text-2xl font-bold text-secondary">
                {stats.averageIntensity.toFixed(1)} / 3
              </p>
            </div>
          </div>
          <div className="card flex items-center p-4" style={{ background: 'rgba(17, 17, 17, 0.8)', backdropFilter: 'blur(4px)' }}>
            <CalendarIcon className="w-6 h-6 sm:w-8 sm:h-8 text-primary mr-3 sm:mr-4 flex-shrink-0" />
            <div className="min-w-0">
              <p className="text-xs sm:text-sm text-secondary truncate">Most Common</p>
              <p className="text-lg sm:text-2xl font-bold text-secondary">
                {Object.entries(stats.workoutsByType)
                  .sort(([,a], [,b]) => b - a)[0]?.[0] 
                  ? workoutTypes.find(t => t.id === Object.entries(stats.workoutsByType)
                      .sort(([,a], [,b]) => b - a)[0]?.[0])?.name
                  : 'N/A'}
              </p>
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="card mt-6" style={{ background: 'rgba(17, 17, 17, 0.8)', backdropFilter: 'blur(4px)' }}>
          <h2 className="text-xl font-bold mb-4 text-secondary">Recent Activity</h2>
          <div className="space-y-4">
            {recentWorkouts.map((workout) => (
              <div
                key={workout.id}
                className="flex items-center justify-between py-2 border-b border-surface-light last:border-0"
              >
                <div>
                  <p className="font-medium text-secondary">
                    {workoutTypes.find(t => t.id === workout.type)?.icon}{' '}
                    {workoutTypes.find(t => t.id === workout.type)?.name}
                  </p>
                  <p className="text-sm text-secondary">{formatDate(workout.date)}</p>
                </div>
                <div className="space-y-1 text-sm text-secondary/80">
                  <p>Duration: {formatDuration((workout.duration.hours || 0) * 60 + (workout.duration.minutes || 0))}</p>
                  <p>Training Zone: Z{workout.trainingZone}</p>
                  {workout.distance && (
                    <p>Distance: {workout.distance.value} {workout.distance.unit}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
