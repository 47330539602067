import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

// Types for our database tables
export type Workout = {
  id: string;
  user_id: string;
  date: string;
  type: 'running' | 'cycling' | 'swimming';
  distance: number;
  duration: number;
  notes?: string;
  created_at: string;
}

// Add more types as we create more tables
