import { useState } from 'react';
import WorkoutForm from '../components/WorkoutForm';
import { Workout, workoutTypes, WorkoutType } from '../types/workout';
import { useWorkouts } from '../context/WorkoutContext';

const Workouts = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingWorkout, setEditingWorkout] = useState<Workout | null>(null);
  const { workouts, addWorkout, updateWorkout, deleteWorkout } = useWorkouts();

  const handleEditWorkout = (workout: Workout) => {
    setEditingWorkout(workout);
    setIsFormOpen(true);
  };

  const handleDeleteWorkout = (id: string) => {
    if (window.confirm('Are you sure you want to delete this workout?')) {
      deleteWorkout(id);
    }
  };

  const formatDuration = (hours: number, minutes: number) => {
    if (hours === 0) return `${minutes} min`;
    if (minutes === 0) return `${hours} hr`;
    return `${hours} hr ${minutes} min`;
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }).format(date);
  };

  const handleSubmit = async (workout: Omit<Workout, 'id'>): Promise<void> => {
    if (editingWorkout) {
      await updateWorkout({ ...workout, id: editingWorkout.id });
    } else {
      await addWorkout(workout);
    }
    setIsFormOpen(false);
    setEditingWorkout(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-secondary">Recent Workouts</h1>
        <button
          onClick={() => {
            setEditingWorkout(null);
            setIsFormOpen(true);
          }}
          className="btn btn-primary"
        >
          Add Workout
        </button>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {workouts.sort((a, b) => b.date.getTime() - a.date.getTime()).map((workout) => (
          <div key={workout.id} className="card">
            <div className="flex justify-between items-start mb-4">
              <div className="flex items-center space-x-2">
                <span className="text-2xl">
                  {workoutTypes.find((t: WorkoutType) => t.id === workout.type)?.icon}
                </span>
                <div>
                  <h3 className="font-medium text-secondary">
                    {workoutTypes.find((t: WorkoutType) => t.id === workout.type)?.name}
                  </h3>
                  <p className="text-secondary/70">
                    {formatDate(workout.date)}
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEditWorkout(workout)}
                  className="text-primary hover:text-primary-dark"
                  title="Edit workout"
                >
                  ✏️
                </button>
                <button
                  onClick={() => handleDeleteWorkout(workout.id)}
                  className="text-red-500 hover:text-red-600"
                  title="Delete workout"
                >
                  🗑️
                </button>
              </div>
            </div>

            <div className="space-y-2 text-secondary/80">
              <p>Duration: {formatDuration((workout.duration.hours || 0), (workout.duration.minutes || 0))}</p>
              <p>Training Zone: Z{workout.trainingZone}</p>
              {workout.distance && (
                <p>Distance: {workout.distance.value} {workout.distance.unit}</p>
              )}
              {workout.notes && (
                <p className="text-sm mt-2 text-secondary/70">{workout.notes}</p>
              )}
            </div>
          </div>
        ))}
      </div>

      <WorkoutForm
        isOpen={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setEditingWorkout(null);
        }}
        onSubmit={handleSubmit}
        isEditing={!!editingWorkout}
        initialData={editingWorkout || undefined}
      />
    </div>
  );
};

export default Workouts;
