import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Schedule from './pages/Schedule';
import Workouts from './pages/Workouts';
import Progress from './pages/Progress';
import Layout from './components/Layout';
import { WorkoutProvider } from './context/WorkoutContext';
import { AuthProvider } from './context/AuthContext';
import { SignIn } from './components/auth/SignIn';
import { SignUp } from './components/auth/SignUp';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { AuthCallback } from './components/auth/AuthCallback';
import { AuthLanding } from './components/auth/AuthLanding';
import { ForgotPassword } from './components/auth/ForgotPassword';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <Router>
      <AuthProvider>
        <WorkoutProvider>
          <Routes>
            {/* Auth routes */}
            <Route path="/auth">
              <Route index element={<AuthLanding />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="callback" element={<AuthCallback />} />
            </Route>
            
            {/* Protected routes */}
            <Route path="/" element={
              <ProtectedRoute>
                <div className="min-h-screen bg-background">
                  <Navbar onMenuClick={() => setSidebarOpen(true)} />
                  <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
                  <main className="pt-16 px-4 md:px-8 lg:px-12">
                    <Dashboard />
                  </main>
                </div>
              </ProtectedRoute>
            } />
            <Route path="/schedule" element={
              <ProtectedRoute>
                <div className="min-h-screen bg-background">
                  <Navbar onMenuClick={() => setSidebarOpen(true)} />
                  <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
                  <main className="pt-16 px-4 md:px-8 lg:px-12">
                    <Schedule />
                  </main>
                </div>
              </ProtectedRoute>
            } />
            <Route path="/workouts" element={
              <ProtectedRoute>
                <div className="min-h-screen bg-background">
                  <Navbar onMenuClick={() => setSidebarOpen(true)} />
                  <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
                  <main className="pt-16 px-4 md:px-8 lg:px-12">
                    <Workouts />
                  </main>
                </div>
              </ProtectedRoute>
            } />
            <Route path="/progress" element={
              <ProtectedRoute>
                <div className="min-h-screen bg-background">
                  <Navbar onMenuClick={() => setSidebarOpen(true)} />
                  <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
                  <main className="pt-16 px-4 md:px-8 lg:px-12">
                    <Progress />
                  </main>
                </div>
              </ProtectedRoute>
            } />

            {/* Redirect unknown routes to home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </WorkoutProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
