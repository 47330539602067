import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Duration, Distance, WorkoutFormData, Workout, workoutTypes } from '../types/workout';
import { FaceHappy, FaceNeutral, FaceSad } from './icons/Faces';

interface WorkoutFormProps {
  isOpen: boolean;
  onClose: () => void;
  isEditing?: boolean;
  onSubmit: (data: WorkoutFormData) => Promise<void>;
  initialData?: Workout;
}

const trainingZones = [
  { id: 1, name: 'Z1', color: 'bg-blue-500', description: 'Very light intensity - Recovery' },
  { id: 2, name: 'Z2', color: 'bg-green-500', description: 'Light intensity - Endurance' },
  { id: 3, name: 'Z3', color: 'bg-yellow-500', description: 'Moderate intensity - Tempo' },
  { id: 4, name: 'Z4', color: 'bg-orange-500', description: 'Hard intensity - Threshold' },
  { id: 5, name: 'Z5', color: 'bg-red-500', description: 'Maximum intensity - Anaerobic' },
];

const intensityLevels = [
  { id: 1, icon: FaceSad },
  { id: 2, icon: FaceNeutral },
  { id: 3, icon: FaceHappy },
];

const WorkoutForm = ({ isOpen, onClose, isEditing = false, onSubmit, initialData }: WorkoutFormProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState('');
  const [duration, setDuration] = useState<Duration>({ hours: null, minutes: null });
  const [selectedZone, setSelectedZone] = useState<number | null>(null);
  const [selectedIntensity, setSelectedIntensity] = useState<number | null>(null);
  const [distance, setDistance] = useState<Distance>({ value: 0, unit: 'km' });
  const [notes, setNotes] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Handle body scroll lock
  useEffect(() => {
    if (isOpen) {
      // Save current scroll position and lock body scroll
      const scrollY = window.scrollY;
      const body = document.body;
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = `-${scrollY}px`;
      body.style.overflow = 'hidden';
      body.style.height = '100%';
      body.style.touchAction = 'none'; // Prevents touch scrolling
      
      // Also lock html element
      const html = document.documentElement;
      html.style.overflow = 'hidden';
      html.style.height = '100%';
      html.style.touchAction = 'none';
    } else {
      // Restore scroll position when modal closes
      const scrollY = document.body.style.top;
      const body = document.body;
      const html = document.documentElement;
      
      // Reset body styles
      body.style.position = '';
      body.style.width = '';
      body.style.top = '';
      body.style.overflow = '';
      body.style.height = '';
      body.style.touchAction = '';
      
      // Reset html styles
      html.style.overflow = '';
      html.style.height = '';
      html.style.touchAction = '';

      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedDate(null);
      setSelectedType('');
      setDuration({ hours: null, minutes: null });
      setSelectedZone(null);
      setSelectedIntensity(null);
      setDistance({ value: 0, unit: 'km' });
      setNotes('');
      setError(null);
      setShowErrorModal(false);
    } else if (isEditing && initialData) {
      setSelectedDate(initialData.date);
      setSelectedType(initialData.type);
      setDuration(initialData.duration);
      setSelectedZone(initialData.trainingZone);
      setSelectedIntensity(initialData.intensity);
      if (initialData.distance) {
        setDistance(initialData.distance);
      }
      setNotes(initialData.notes || '');
    }
  }, [isOpen, isEditing, initialData]);

  const handleError = (message: string) => {
    setError(message);
    setShowErrorModal(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setShowErrorModal(false);

    if (!selectedDate || !selectedType || !selectedZone || !selectedIntensity) {
      handleError('Please fill in all required fields');
      return;
    }

    if (!duration.hours && !duration.minutes) {
      handleError('Please enter a duration');
      return;
    }

    if (distance.value < 0) {
      handleError('Distance must be a positive number');
      return;
    }

    try {
      const workoutData: WorkoutFormData = {
        type: selectedType,
        date: selectedDate,
        duration: {
          hours: duration.hours || 0,
          minutes: duration.minutes || 0
        },
        trainingZone: selectedZone,
        intensity: selectedIntensity,
        ...(distance.value > 0 && { distance }),
        ...(notes && { notes })
      };

      await onSubmit(workoutData);
      onClose();
    } catch (err) {
      console.error('Error submitting workout:', err);
      handleError(err instanceof Error ? err.message : 'Failed to save workout');
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-surface px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 max-h-[90vh] overflow-y-auto mt-16 sm:mt-8">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-secondary">
                      {isEditing ? 'Edit Workout' : 'Add New Workout'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <form onSubmit={handleSubmit} className="space-y-4 pb-6 sm:pb-8">
                        {/* Workout Type */}
                        <div className="form-group">
                          <label htmlFor="workoutType" className="label">
                            Workout Type
                          </label>
                          <select
                            id="workoutType"
                            className="input w-full"
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            required
                          >
                            <option value="" disabled>Select a type</option>
                            {workoutTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.icon} {type.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="form-group">
                            <label className="block text-sm font-medium text-secondary mb-1">
                              Date
                            </label>
                            <div 
                              className="relative w-full cursor-pointer rounded-md overflow-hidden"
                              onClick={() => {
                                const dateInput = document.getElementById('workout-date') as HTMLInputElement;
                                if (dateInput) {
                                  try {
                                    // Modern browsers
                                    if (typeof dateInput.showPicker === 'function') {
                                      dateInput.showPicker();
                                    } else {
                                      // Fallback for older browsers
                                      dateInput.click();
                                    }
                                  } catch (e) {
                                    // Ultimate fallback if showPicker fails
                                    dateInput.click();
                                  }
                                }
                              }}
                            >
                              <input
                                id="workout-date"
                                type="date"
                                value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
                                onChange={(e) => {
                                  const date = new Date(e.target.value);
                                  date.setHours(12, 0, 0, 0);
                                  setSelectedDate(date);
                                }}
                                className="input w-full cursor-pointer"
                                required
                              />
                              <div className="absolute inset-0 pointer-events-none" />
                              <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                                <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="block text-sm font-medium text-secondary mb-1">
                              Duration
                            </label>
                            <div className="grid grid-cols-2 gap-2">
                              <input
                                type="number"
                                placeholder="Hours"
                                value={duration.hours === null ? '' : duration.hours}
                                onChange={(e) => setDuration(prev => ({
                                  ...prev,
                                  hours: e.target.value === '' ? null : Number(e.target.value)
                                }))}
                                min="0"
                                className="input w-full"
                              />
                              <input
                                type="number"
                                placeholder="Minutes"
                                value={duration.minutes === null ? '' : duration.minutes}
                                onChange={(e) => setDuration(prev => ({
                                  ...prev,
                                  minutes: e.target.value === '' ? null : Number(e.target.value)
                                }))}
                                min="0"
                                max="59"
                                className="input w-full"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Training Zone */}
                        <div className="form-group space-y-3">
                          <label className="label">Training Zone</label>
                          <div className="grid grid-cols-5 gap-1 sm:gap-2">
                            {trainingZones.map((zone) => (
                              <button
                                key={zone.id}
                                type="button"
                                onClick={() => setSelectedZone(zone.id)}
                                className={`relative flex flex-col items-center p-2 rounded-lg bg-surface-light hover:bg-surface-light/80 transition-all
                                  ${selectedZone === zone.id ? 'ring-2 ring-primary' : ''}`}
                              >
                                <span className={`w-4 h-4 rounded-full ${zone.color} mb-1`} />
                                <span className="text-sm font-medium text-secondary">
                                  {zone.name}
                                </span>
                              </button>
                            ))}
                          </div>
                          <div className="relative overflow-hidden">
                            <div className={`p-3 rounded-lg bg-surface-light/50 text-sm text-secondary/90 transition-all duration-300 ease-in-out
                              ${selectedZone ? 'opacity-100 translate-y-0' : 'opacity-50 translate-y-1'}`}>
                              {selectedZone ? trainingZones.find(zone => zone.id === selectedZone)?.description : 'Select a training zone to see description'}
                            </div>
                          </div>
                        </div>

                        {/* Intensity Level */}
                        <div className="form-group">
                          <label className="label">How did it feel?</label>
                          <div className="flex justify-center gap-12 mt-2">
                            {intensityLevels.map((level) => (
                              <button
                                key={level.id}
                                type="button"
                                onClick={() => setSelectedIntensity(level.id)}
                                className={`flex items-center p-3 rounded-lg hover:bg-surface-light/50 transition-all text-primary
                                  ${selectedIntensity === level.id ? 'bg-surface-light ring-2 ring-primary' : ''}`}
                              >
                                <level.icon />
                              </button>
                            ))}
                          </div>
                        </div>

                        {/* Distance */}
                        <div className="form-group">
                          <label htmlFor="distance" className="label">
                            Distance (optional)
                          </label>
                          <div className="flex space-x-2">
                            <input
                              type="number"
                              id="distance"
                              className="input flex-1"
                              placeholder="0.0"
                              min="0"
                              step="0.1"
                              value={distance.value || ''}
                              onChange={(e) => setDistance(prev => ({ ...prev, value: parseFloat(e.target.value) || 0 }))}
                            />
                            <select 
                              className="input w-24"
                              value={distance.unit}
                              onChange={(e) => setDistance(prev => ({ ...prev, unit: e.target.value as 'km' | 'miles' }))}
                            >
                              <option value="km">km</option>
                              <option value="miles">miles</option>
                            </select>
                          </div>
                        </div>

                        {/* Notes */}
                        <div className="form-group">
                          <label htmlFor="notes" className="label">
                            Notes (optional)
                          </label>
                          <textarea
                            id="notes"
                            rows={3}
                            className="input w-full"
                            placeholder="Add any additional notes here..."
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                          />
                        </div>

                        {/* Form Actions */}
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:col-start-2"
                          >
                            {isEditing ? 'Save Changes' : 'Add Workout'}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-surface px-3 py-2 text-sm font-semibold text-secondary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={onClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {/* Error Modal */}
        <Transition.Root show={showErrorModal} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setShowErrorModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-surface px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-secondary">
                          Form Error
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-secondary">
                            {error}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-dark focus:outline-none"
                        onClick={() => setShowErrorModal(false)}
                      >
                        OK
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Dialog>
    </Transition.Root>
  );
};

export default WorkoutForm;
