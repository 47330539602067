import React from 'react';

export function TriathlonAnimation() {
  return (
    <div className="w-full max-w-md mx-auto relative h-[280px]"> 
      {/* Swimming Animation */}
      <svg
        className="w-full h-32 animate-float opacity-90" 
        viewBox="0 0 200 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="animate-wave"
          d="M0 50 Q 25 40, 50 50 T 100 50 T 150 50 T 200 50"
          stroke="#22C55E"
          strokeWidth="2"
          fill="none"
        />
        <circle
          className="animate-swim"
          cx="50"
          cy="45"
          r="6"
          fill="#22C55E"
        />
      </svg>

      {/* Cycling Animation */}
      <svg
        className="w-full h-32 -mt-8 opacity-90" 
        viewBox="0 0 200 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="animate-wheel"
          cx="70"
          cy="50"
          r="15"
          stroke="#22C55E"
          strokeWidth="2"
          fill="none"
        />
        <circle
          className="animate-wheel"
          cx="130"
          cy="50"
          r="15"
          stroke="#22C55E"
          strokeWidth="2"
          fill="none"
        />
        <path
          d="M70 50 L100 30 L130 50"
          stroke="#22C55E"
          strokeWidth="2"
        />
        <circle
          cx="100"
          cy="30"
          r="4"
          fill="#22C55E"
          className="animate-pedal"
        />
      </svg>

      {/* Running Animation */}
      <svg
        className="w-full h-32 -mt-8 opacity-90" 
        viewBox="0 0 200 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="animate-run"
          cx="100"
          cy="50"
          r="6"
          fill="#22C55E"
        />
        <path
          className="animate-bounce"
          d="M80 70 Q 100 20, 120 70"
          stroke="#22C55E"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </div>
  );
}
