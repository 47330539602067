import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Workout } from '../types/workout';
import { getWorkouts, saveWorkout, updateWorkout, deleteWorkout } from '../utils/workoutStorage';
import { useAuth } from './AuthContext';

interface WorkoutContextType {
  workouts: Workout[];
  loading: boolean;
  error: string | null;
  addWorkout: (workout: Omit<Workout, 'id'>) => Promise<void>;
  updateWorkout: (workout: Workout) => Promise<void>;
  deleteWorkout: (id: string) => Promise<void>;
  recentWorkouts: Workout[];
  getWorkoutsByDateRange: (startDate: Date, endDate: Date) => Workout[];
  getWorkoutStats: () => {
    totalWorkouts: number;
    totalDuration: number;
    averageIntensity: number;
    workoutsByType: Record<string, number>;
  };
}

const WorkoutContext = createContext<WorkoutContextType | undefined>(undefined);

export const WorkoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchWorkouts = async () => {
      if (!user) {
        setWorkouts([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const fetchedWorkouts = await getWorkouts();
        const processedWorkouts = fetchedWorkouts.map(workout => ({
          ...workout,
          date: new Date(workout.date)
        }));
        setWorkouts(processedWorkouts);
        setError(null);
      } catch (err) {
        console.error('Error fetching workouts:', err);
        setError('Failed to load workouts');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkouts();
  }, [user]);

  const addWorkout = async (workoutData: Omit<Workout, 'id'>) => {
    if (!user) {
      throw new Error('You must be logged in to add a workout');
    }

    try {
      setLoading(true);
      const newWorkout = await saveWorkout({
        ...workoutData,
        user_id: user.id,
      });
      setWorkouts(prev => [
        {
          ...newWorkout,
          date: new Date(newWorkout.date)
        },
        ...prev
      ]);
      setError(null);
    } catch (err) {
      console.error('Error adding workout:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to add workout';
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateWorkoutData = async (workout: Workout) => {
    try {
      setLoading(true);
      await updateWorkout(workout);
      setWorkouts(prev => prev.map(w => w.id === workout.id ? {
        ...workout,
        date: new Date(workout.date)
      } : w));
      setError(null);
    } catch (err) {
      console.error('Error updating workout:', err);
      setError('Failed to update workout');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteWorkoutData = async (id: string) => {
    try {
      setLoading(true);
      await deleteWorkout(id);
      setWorkouts(prev => prev.filter(w => w.id !== id));
      setError(null);
    } catch (err) {
      console.error('Error deleting workout:', err);
      setError('Failed to delete workout');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const recentWorkouts = workouts && workouts.length > 0 
    ? [...workouts]
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .slice(0, 5)
    : [];

  const getWorkoutsByDateRange = (startDate: Date, endDate: Date) => {
    return workouts.filter(workout => {
      const workoutDate = new Date(workout.date);
      return workoutDate >= startDate && workoutDate <= endDate;
    });
  };

  const getWorkoutStats = () => {
    const totalWorkouts = workouts.length;
    const totalDuration = workouts.reduce((total, workout) => 
      total + (workout.duration.hours || 0) * 60 + (workout.duration.minutes || 0), 0);
    const averageIntensity = workouts.reduce((total, workout) => 
      total + workout.intensity, 0) / (totalWorkouts || 1);
    
    const workoutsByType = workouts.reduce((acc, workout) => {
      acc[workout.type] = (acc[workout.type] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return {
      totalWorkouts,
      totalDuration,
      averageIntensity,
      workoutsByType,
    };
  };

  const value = {
    workouts,
    loading,
    error,
    addWorkout,
    updateWorkout: updateWorkoutData,
    deleteWorkout: deleteWorkoutData,
    recentWorkouts,
    getWorkoutsByDateRange,
    getWorkoutStats,
  };

  return (
    <WorkoutContext.Provider value={value}>
      {children}
    </WorkoutContext.Provider>
  );
};

export const useWorkouts = () => {
  const context = useContext(WorkoutContext);
  if (context === undefined) {
    throw new Error('useWorkouts must be used within a WorkoutProvider');
  }
  return context;
};
